import { ComponentPropsWithoutRef } from 'react';

export const LogoMobile = (props: ComponentPropsWithoutRef<'svg'>) => {
    return (
        <svg width="105" height="20" viewBox="0 0 105 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5607 0L10.9482 1.05954C10.9345 1.08328 10.9207 1.107 10.9071 1.13076L0 20H23.1213L11.5607 0ZM7.71889 9.48181L2.46739 18.5669C5.19877 18.5016 7.65513 18.1838 9.48888 17.2605C10.0736 16.9661 10.5901 16.6123 11.0311 16.1875C9.86466 15.3912 9.02458 14.4749 8.47767 13.4501C7.82178 12.2211 7.61542 10.8884 7.71618 9.5177C7.71706 9.50574 7.71796 9.49378 7.71889 9.48181ZM10.0199 18.581H20.6435C19.1966 16.2079 17.6283 14.1335 15.8673 12.8973C15.3279 12.5186 14.7768 12.2233 14.2106 12.0217C14.1138 13.519 13.7425 14.7728 13.1248 15.8111C12.3974 17.0335 11.3569 17.9087 10.127 18.5279C10.0915 18.5458 10.0558 18.5635 10.0199 18.581ZM16.7217 11.7641L11.5689 2.84975C10.2484 5.26765 9.28269 7.57112 9.13201 9.62088C9.09527 10.1207 9.10742 10.6014 9.17384 11.0626C10.4398 10.4722 11.6561 10.2323 12.8238 10.3021C14.2391 10.3867 15.5228 10.9223 16.683 11.7369C16.6959 11.7459 16.7088 11.755 16.7217 11.7641ZM9.73044 12.7822C9.67173 12.6722 9.61745 12.5608 9.56748 12.448C10.7306 11.8673 11.7818 11.6607 12.7398 11.718C12.7619 11.7193 12.7839 11.7207 12.8059 11.7223C12.7431 13.1279 12.4178 14.2181 11.9138 15.0712C10.8603 14.3685 10.1643 13.5952 9.73044 12.7822Z"
                fill="#F3F3F3"
            />
            <path
                d="M85.719 7.92363V13.4518C85.719 13.8263 85.8036 14.0982 85.9728 14.2674C86.154 14.4245 86.4561 14.503 86.879 14.503H88.1478V16.2249H86.5165C85.5861 16.2249 84.8732 16.0074 84.3778 15.5724C83.8824 15.1374 83.6347 14.4305 83.6347 13.4518V7.92363H82.4565V6.23801H83.6347V3.75488H85.719V6.23801H88.1478V7.92363H85.719Z"
                fill="#FAFAFA"
            />
            <path
                d="M81.2745 10.9871C81.2745 11.3617 81.2503 11.7 81.202 12.0021H73.5714C73.6318 12.7996 73.9278 13.44 74.4595 13.9233C74.9912 14.4067 75.6437 14.6483 76.417 14.6483C77.5287 14.6483 78.3141 14.1831 78.7732 13.2527H81.0026C80.7005 14.171 80.1507 14.9262 79.3532 15.5183C78.5678 16.0983 77.5891 16.3883 76.417 16.3883C75.4624 16.3883 74.6045 16.1769 73.8432 15.7539C73.0941 15.3189 72.502 14.7148 72.067 13.9414C71.6441 13.156 71.4326 12.2498 71.4326 11.2227C71.4326 10.1956 71.638 9.2954 72.0489 8.52207C72.4718 7.73665 73.0578 7.13249 73.807 6.70957C74.5682 6.28665 75.4382 6.0752 76.417 6.0752C77.3595 6.0752 78.1993 6.28061 78.9364 6.69144C79.6735 7.10228 80.2474 7.68228 80.6582 8.43145C81.0691 9.16853 81.2745 10.0204 81.2745 10.9871ZM79.1176 10.3346C79.1055 9.57332 78.8337 8.96311 78.302 8.50394C77.7703 8.04478 77.1118 7.8152 76.3264 7.8152C75.6135 7.8152 75.0032 8.04478 74.4957 8.50394C73.9882 8.95103 73.6862 9.56124 73.5895 10.3346H79.1176Z"
                fill="#FAFAFA"
            />
            <path
                d="M66.3995 11.2406L71.0032 16.225H68.212L64.5145 11.9294V16.225H62.4482V2.8125H64.5145V10.6062L68.1395 6.23812H71.0032L66.3995 11.2406Z"
                fill="#FAFAFA"
            />
            <path
                d="M57.5579 7.68832C57.86 7.18082 58.2588 6.78811 58.7542 6.5102C59.2617 6.2202 59.8598 6.0752 60.5486 6.0752V8.21394H60.0229C59.2134 8.21394 58.5971 8.41936 58.1742 8.83019C57.7634 9.24103 57.5579 9.95395 57.5579 10.9689V16.2252H55.4917V6.23832H57.5579V7.68832Z"
                fill="#FAFAFA"
            />
            <path
                d="M42.519 11.1864C42.519 10.1835 42.7245 9.2954 43.1353 8.52207C43.5582 7.74874 44.1261 7.15061 44.839 6.72769C45.564 6.2927 46.3615 6.0752 47.2315 6.0752C48.017 6.0752 48.6997 6.23228 49.2797 6.54644C49.8718 6.84853 50.343 7.22915 50.6934 7.68832V6.23832H52.7778V16.2252H50.6934V14.7389C50.343 15.2102 49.8657 15.6029 49.2615 15.9171C48.6574 16.2312 47.9686 16.3883 47.1953 16.3883C46.3374 16.3883 45.552 16.1708 44.839 15.7358C44.1261 15.2887 43.5582 14.6725 43.1353 13.8871C42.7245 13.0896 42.519 12.1894 42.519 11.1864ZM50.6934 11.2227C50.6934 10.5339 50.5484 9.93582 50.2584 9.42832C49.9805 8.92082 49.612 8.53415 49.1528 8.26832C48.6936 8.00249 48.1982 7.86957 47.6665 7.86957C47.1349 7.86957 46.6395 8.00249 46.1803 8.26832C45.7211 8.52207 45.3465 8.90269 45.0565 9.41019C44.7786 9.90561 44.6397 10.4977 44.6397 11.1864C44.6397 11.8752 44.7786 12.4794 45.0565 12.9989C45.3465 13.5185 45.7211 13.9173 46.1803 14.1952C46.6515 14.461 47.147 14.5939 47.6665 14.5939C48.1982 14.5939 48.6936 14.461 49.1528 14.1952C49.612 13.9294 49.9805 13.5427 50.2584 13.0352C50.5484 12.5156 50.6934 11.9114 50.6934 11.2227Z"
                fill="#FAFAFA"
            />
            <path
                d="M36.4644 6.0752C37.2498 6.0752 37.9506 6.23832 38.5669 6.56457C39.1952 6.89082 39.6846 7.37415 40.035 8.01457C40.3975 8.65499 40.5788 9.42832 40.5788 10.3346V16.2252H38.5306V10.6427C38.5306 9.74853 38.3071 9.06582 37.86 8.59457C37.4129 8.11124 36.8027 7.86957 36.0294 7.86957C35.256 7.86957 34.6398 8.11124 34.1806 8.59457C33.7335 9.06582 33.51 9.74853 33.51 10.6427V16.2252H31.4619V10.6427C31.4619 9.74853 31.2383 9.06582 30.7913 8.59457C30.3442 8.11124 29.734 7.86957 28.9606 7.86957C28.1873 7.86957 27.571 8.11124 27.1119 8.59457C26.6648 9.06582 26.4413 9.74853 26.4413 10.6427V16.2252H24.375V6.23832H26.4413V7.38019C26.7796 6.96936 27.2085 6.64915 27.7281 6.41957C28.2477 6.18999 28.8035 6.0752 29.3956 6.0752C30.1931 6.0752 30.906 6.24436 31.5344 6.5827C32.1627 6.92103 32.646 7.4104 32.9844 8.05082C33.2865 7.44665 33.7577 6.96936 34.3981 6.61894C35.0385 6.25645 35.7273 6.0752 36.4644 6.0752Z"
                fill="#FAFAFA"
            />
        </svg>
    );
};
