import { ComponentPropsWithoutRef } from 'react';

export const LogoDesktop = (props: ComponentPropsWithoutRef<'svg'>) => {
    return (
        <svg width="172" height="24" viewBox="0 0 172 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8728 0L13.1379 1.27145C13.1214 1.29994 13.1049 1.3284 13.0885 1.35691L0 24H27.7456L13.8728 0ZM9.26267 11.3782L2.96087 22.2803C6.23853 22.2019 9.18615 21.8206 11.3867 20.7126C12.0883 20.3593 12.7081 19.9348 13.2373 19.425C11.8376 18.4695 10.8295 17.3698 10.1732 16.1401C9.38613 14.6653 9.1385 13.0661 9.25942 11.4212C9.26047 11.4069 9.26156 11.3925 9.26267 11.3782ZM12.0239 22.2972H24.7722C23.0359 19.4495 21.154 16.9603 19.0407 15.4767C18.3934 15.0223 17.7321 14.6679 17.0527 14.4261C16.9365 16.2228 16.491 17.7274 15.7497 18.9733C14.8769 20.4402 13.6283 21.4904 12.1525 22.2335C12.1098 22.255 12.067 22.2762 12.0239 22.2972ZM20.0661 14.117L13.8827 3.4197C12.2981 6.32118 11.1392 9.08534 10.9584 11.5451C10.9143 12.1449 10.9289 12.7217 11.0086 13.2751C12.5277 12.5666 13.9873 12.2788 15.3886 12.3625C17.087 12.464 18.6274 13.1068 20.0197 14.0842C20.0351 14.0951 20.0506 14.106 20.0661 14.117ZM11.6765 15.3387C11.6061 15.2067 11.5409 15.073 11.481 14.9375C12.8768 14.2407 14.1382 13.9929 15.2878 14.0615C15.3142 14.0631 15.3407 14.0649 15.3671 14.0668C15.2917 15.7535 14.9013 17.0618 14.2965 18.0854C13.0324 17.2421 12.1972 16.3143 11.6765 15.3387Z"
                fill="#F3F3F3"
            />
            <path
                d="M59.9291 5.92794C59.4796 5.92794 59.1026 5.77569 58.7981 5.47119C58.4936 5.16669 58.3413 4.78969 58.3413 4.34019C58.3413 3.89069 58.4936 3.51369 58.7981 3.20919C59.1026 2.90469 59.4796 2.75244 59.9291 2.75244C60.3641 2.75244 60.7338 2.90469 61.0383 3.20919C61.3428 3.51369 61.4951 3.89069 61.4951 4.34019C61.4951 4.78969 61.3428 5.16669 61.0383 5.47119C60.7338 5.77569 60.3641 5.92794 59.9291 5.92794ZM61.1471 7.51569V19.4999H58.6676V7.51569H61.1471Z"
                fill="#FAFAFA"
            />
            <path
                d="M46.8418 9.27732C47.2623 8.72632 47.8351 8.26232 48.5601 7.88532C49.2851 7.50832 50.1043 7.31982 51.0178 7.31982C52.0618 7.31982 53.0116 7.58082 53.8671 8.10282C54.7371 8.61032 55.4186 9.32807 55.9116 10.2561C56.4046 11.1841 56.6511 12.2498 56.6511 13.4533C56.6511 14.6568 56.4046 15.7371 55.9116 16.6941C55.4186 17.6366 54.7371 18.3761 53.8671 18.9126C53.0116 19.4346 52.0618 19.6956 51.0178 19.6956C50.1043 19.6956 49.2923 19.5143 48.5818 19.1518C47.8713 18.7748 47.2913 18.3108 46.8418 17.7598V25.1983H44.3623V7.51557H46.8418V9.27732ZM54.1281 13.4533C54.1281 12.6268 53.9541 11.9163 53.6061 11.3218C53.2726 10.7128 52.8231 10.2561 52.2576 9.95157C51.7066 9.63257 51.1121 9.47307 50.4741 9.47307C49.8506 9.47307 49.2561 9.63257 48.6906 9.95157C48.1396 10.2706 47.6901 10.7346 47.3421 11.3436C47.0086 11.9526 46.8418 12.6703 46.8418 13.4968C46.8418 14.3233 47.0086 15.0483 47.3421 15.6718C47.6901 16.2808 48.1396 16.7448 48.6906 17.0638C49.2561 17.3828 49.8506 17.5423 50.4741 17.5423C51.1121 17.5423 51.7066 17.3828 52.2576 17.0638C52.8231 16.7303 53.2726 16.2518 53.6061 15.6283C53.9541 15.0048 54.1281 14.2798 54.1281 13.4533Z"
                fill="#FAFAFA"
            />
            <path
                d="M29.23 13.4533C29.23 12.2498 29.4765 11.1841 29.9695 10.2561C30.477 9.32807 31.1585 8.61032 32.014 8.10282C32.884 7.58082 33.841 7.31982 34.885 7.31982C35.8275 7.31982 36.6467 7.50832 37.3427 7.88532C38.0532 8.24782 38.6187 8.70457 39.0392 9.25557V7.51557H41.5405V19.4998H39.0392V17.7163C38.6187 18.2818 38.046 18.7531 37.321 19.1301C36.596 19.5071 35.7695 19.6956 34.8415 19.6956C33.812 19.6956 32.8695 19.4346 32.014 18.9126C31.1585 18.3761 30.477 17.6366 29.9695 16.6941C29.4765 15.7371 29.23 14.6568 29.23 13.4533ZM39.0392 13.4968C39.0392 12.6703 38.8652 11.9526 38.5172 11.3436C38.1837 10.7346 37.7415 10.2706 37.1905 9.95157C36.6395 9.63257 36.045 9.47307 35.407 9.47307C34.769 9.47307 34.1745 9.63257 33.6235 9.95157C33.0725 10.2561 32.623 10.7128 32.275 11.3218C31.9415 11.9163 31.7747 12.6268 31.7747 13.4533C31.7747 14.2798 31.9415 15.0048 32.275 15.6283C32.623 16.2518 33.0725 16.7303 33.6235 17.0638C34.189 17.3828 34.7835 17.5423 35.407 17.5423C36.045 17.5423 36.6395 17.3828 37.1905 17.0638C37.7415 16.7448 38.1837 16.2808 38.5172 15.6718C38.8652 15.0483 39.0392 14.3233 39.0392 13.4968Z"
                fill="#FAFAFA"
            />
            <path
                d="M64.1174 7.55917C64.2044 6.21066 64.7192 5.15941 65.6617 4.40542C66.6187 3.65141 67.8367 3.27441 69.3157 3.27441C70.3307 3.27441 71.2079 3.45566 71.9474 3.81816C72.6869 4.18066 73.2452 4.67366 73.6222 5.29716C73.9992 5.92066 74.1877 6.62391 74.1877 7.40691C74.1877 8.30591 73.9484 9.07442 73.4699 9.71242C72.9914 10.3504 72.4187 10.7782 71.7517 10.9957V11.0827C72.6072 11.3437 73.2742 11.8222 73.7527 12.5182C74.2312 13.1997 74.4704 14.0769 74.4704 15.1499C74.4704 16.0054 74.2747 16.7667 73.8832 17.4337C73.4917 18.1007 72.9117 18.6299 72.1432 19.0214C71.3747 19.3984 70.4539 19.5869 69.3809 19.5869C67.8149 19.5869 66.5244 19.1882 65.5094 18.3907C64.5089 17.5787 63.9724 16.4187 63.8999 14.9107H66.2924C66.3504 15.6792 66.6477 16.3099 67.1842 16.8029C67.7207 17.2814 68.4457 17.5207 69.3592 17.5207C70.2437 17.5207 70.9252 17.2814 71.4037 16.8029C71.8822 16.3099 72.1214 15.6792 72.1214 14.9107C72.1214 13.8957 71.7952 13.1779 71.1427 12.7574C70.5047 12.3224 69.5187 12.1049 68.1847 12.1049H67.6191V10.0604H68.2064C69.3809 10.0459 70.2727 9.85016 70.8816 9.47317C71.5052 9.09617 71.8169 8.50166 71.8169 7.68966C71.8169 6.99366 71.5922 6.44266 71.1427 6.03666C70.6932 5.61617 70.0552 5.40592 69.2287 5.40592C68.4167 5.40592 67.7859 5.61617 67.3364 6.03666C66.8869 6.44266 66.6187 6.95017 66.5317 7.55917H64.1174Z"
                fill="#FAFAFA"
            />
            <path
                d="M151.837 9.50836V16.1421C151.837 16.5916 151.939 16.9179 152.142 17.1209C152.359 17.3094 152.722 17.4036 153.229 17.4036H154.752V19.4699H152.794C151.678 19.4699 150.822 19.2089 150.228 18.6869C149.633 18.1649 149.336 17.3166 149.336 16.1421V9.50836H147.922V7.48561H149.336V4.50586H151.837V7.48561H154.752V9.50836H151.837Z"
                fill="#99A0E4"
            />
            <path
                d="M146.505 13.1843C146.505 13.6338 146.476 14.0398 146.418 14.4023H137.261C137.333 15.3593 137.689 16.1278 138.327 16.7078C138.965 17.2878 139.748 17.5778 140.676 17.5778C142.01 17.5778 142.952 17.0195 143.503 15.903H146.178C145.816 17.005 145.156 17.9113 144.199 18.6218C143.257 19.3178 142.082 19.6658 140.676 19.6658C139.53 19.6658 138.501 19.412 137.587 18.9045C136.688 18.3825 135.978 17.6575 135.456 16.7295C134.948 15.787 134.694 14.6995 134.694 13.467C134.694 12.2345 134.941 11.1543 135.434 10.2263C135.941 9.28379 136.645 8.55879 137.544 8.05129C138.457 7.54379 139.501 7.29004 140.676 7.29004C141.807 7.29004 142.814 7.53654 143.699 8.02954C144.583 8.52254 145.272 9.21854 145.765 10.1175C146.258 11.002 146.505 12.0243 146.505 13.1843ZM143.916 12.4013C143.902 11.4878 143.576 10.7555 142.938 10.2045C142.3 9.65354 141.509 9.37804 140.567 9.37804C139.711 9.37804 138.979 9.65354 138.37 10.2045C137.761 10.741 137.399 11.4733 137.283 12.4013H143.916Z"
                fill="#99A0E4"
            />
            <path
                d="M128.654 13.4888L134.179 19.47H130.829L126.392 14.3153V19.47H123.913V3.375H126.392V12.7275L130.742 7.48575H134.179L128.654 13.4888Z"
                fill="#99A0E4"
            />
            <path
                d="M118.045 9.22579C118.407 8.61679 118.886 8.14554 119.48 7.81204C120.089 7.46404 120.807 7.29004 121.634 7.29004V9.85654H121.003C120.031 9.85654 119.292 10.103 118.784 10.596C118.291 11.089 118.045 11.9445 118.045 13.1625V19.47H115.565V7.48579H118.045V9.22579Z"
                fill="#99A0E4"
            />
            <path
                d="M99.998 13.4235C99.998 12.22 100.245 11.1543 100.738 10.2263C101.245 9.29829 101.927 8.58054 102.782 8.07304C103.652 7.55104 104.609 7.29004 105.653 7.29004C106.596 7.29004 107.415 7.47854 108.111 7.85554C108.821 8.21804 109.387 8.67479 109.807 9.22579V7.48579H112.309V19.47H109.807V17.6865C109.387 18.252 108.814 18.7233 108.089 19.1003C107.364 19.4773 106.538 19.6658 105.61 19.6658C104.58 19.6658 103.638 19.4048 102.782 18.8828C101.927 18.3463 101.245 17.6068 100.738 16.6643C100.245 15.7073 99.998 14.627 99.998 13.4235ZM109.807 13.467C109.807 12.6405 109.633 11.9228 109.285 11.3138C108.952 10.7048 108.51 10.2408 107.959 9.92179C107.408 9.60279 106.813 9.44329 106.175 9.44329C105.537 9.44329 104.943 9.60279 104.392 9.92179C103.841 10.2263 103.391 10.683 103.043 11.292C102.71 11.8865 102.543 12.597 102.543 13.4235C102.543 14.25 102.71 14.975 103.043 15.5985C103.391 16.222 103.841 16.7005 104.392 17.034C104.957 17.353 105.552 17.5125 106.175 17.5125C106.813 17.5125 107.408 17.353 107.959 17.034C108.51 16.715 108.952 16.251 109.285 15.642C109.633 15.0185 109.807 14.2935 109.807 13.467Z"
                fill="#99A0E4"
            />
            <path
                d="M92.7323 7.29004C93.6748 7.29004 94.5158 7.48579 95.2553 7.87729C96.0093 8.26879 96.5966 8.84879 97.0171 9.61729C97.4521 10.3858 97.6696 11.3138 97.6696 12.4013V19.47H95.2118V12.771C95.2118 11.698 94.9436 10.8788 94.4071 10.3133C93.8706 9.73329 93.1383 9.44329 92.2103 9.44329C91.2823 9.44329 90.5428 9.73329 89.9918 10.3133C89.4553 10.8788 89.1871 11.698 89.1871 12.771V19.47H86.7293V12.771C86.7293 11.698 86.4611 10.8788 85.9246 10.3133C85.3881 9.73329 84.6558 9.44329 83.7278 9.44329C82.7998 9.44329 82.0603 9.73329 81.5093 10.3133C80.9728 10.8788 80.7046 11.698 80.7046 12.771V19.47H78.2251V7.48579H80.7046V8.85604C81.1106 8.36304 81.6253 7.97879 82.2488 7.70329C82.8723 7.42779 83.5393 7.29004 84.2498 7.29004C85.2068 7.29004 86.0623 7.49304 86.8163 7.89904C87.5703 8.30504 88.1503 8.89229 88.5563 9.66079C88.9188 8.93579 89.4843 8.36304 90.2528 7.94254C91.0213 7.50754 91.8478 7.29004 92.7323 7.29004Z"
                fill="#99A0E4"
            />
        </svg>
    );
};
